import React from 'react';

import ReactDOM from 'react-dom/client';
import App from './App';
import ThemeProvider, { ThemedGlobalStyle } from './theme';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

if (!!(window as any).ethereum) {
  (window as any).ethereum.autoRefreshOnNetworkChange = false;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider>
      <ThemedGlobalStyle />
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
