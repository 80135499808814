import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

const Wrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 60px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  padding: 6px 20px;
  `}
`;

const Logo = styled.img`
  width: 81px;
  height: 51px;
  margin-right: 100px;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
  `}
`;
export default function Header() {
  return (
    <Wrapper>
      <Logo src={require('assets/img/logo.png')} />
      {/* <LinkWrapper>
        <TYPE.main>Our Solutions</TYPE.main>
        <TYPE.main marginLeft={20}>APAC Opportunity</TYPE.main>
      </LinkWrapper> */}
    </Wrapper>
  );
}
